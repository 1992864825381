<template>
  <div class="d-flex flex-column flex-root">
    <Loader
      v-if="loaderEnabled"
      v-bind:logo="loaderLogo"
      :badge="$gettext('Disconnected')"
      :description="$gettext('You have been disconnected, you are going to be redirected to the login page...')"
    ></Loader>

    <b-button variant="primary" href="https://www.opatrace.com" size="lg" class="fixed-top"><translate>Click here to re-connect now</translate></b-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loader from "@/view/layout/content/Loader";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";

export default {
  name: "disconnected",
  components: {
    Loader,
  },
  beforeCreate() {
    // Load mode
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
  },
  mounted() {
    console.info("Disconnected.vue");

    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

      window.location = "https://www.opatrace.com";
    }, 2000);
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    backgroundImage() {
      return process.env.BASE_URL + "media/error/bgerror.jpg";
    },

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
  },
};
</script>
